import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';

const getPaths = function getPaths(pathname) {
  var paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce(function(prev, curr) {
    var currPath = prev + '/' + curr;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

const findRouteName = function findRouteName(url, routes) {
  var aroute = routes.find(function(route) {
    return matchPath(url, { path: route.path, exact: route.exact });
  });

  return aroute && aroute.name ? aroute.name : null;
};

const propTypes = {
  className: PropTypes.string,
  appRoutes: PropTypes.any
};

const defaultProps = {
  className: '',
  appRoutes: [{ path: '/', exact: true, name: 'Home', component: null }]
};

const AppBreadcrumb = props => {
  const location = useLocation();
  const { className, appRoutes } = props;

  const paths = getPaths(location.pathname);
  const lastPath = paths.splice(paths.length - 1)[0];

  return (
    <Breadcrumb className={className}>
      {paths.map((path, index) => {
        if (path === '/call-flow-gui') {
          return (
            <BreadcrumbItem key={index}>
              <Link to="/call-flow">
                {findRouteName('/call-flow', appRoutes)}
              </Link>
            </BreadcrumbItem>
          );
        }

        return (
          <BreadcrumbItem key={index}>
            <Link to={path}>{findRouteName(path, appRoutes)}</Link>
          </BreadcrumbItem>
        );
      })}
      <BreadcrumbItem active>
        {findRouteName(lastPath, appRoutes)}
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

AppBreadcrumb.propTypes = propTypes;
AppBreadcrumb.defaultProps = defaultProps;

export default AppBreadcrumb;
