import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { history } from '../../routers/AppRouter';
import { startSetImpersonateData } from '../../actions/profile';
import AsyncSelect from '../../components/AsyncSelect';
import { getFilteredGroups } from '../../actions/async_select_actions';

import { deserializeData, serializeProfile } from './impersonateSerializer';

class ImpersonateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { group: [], accountId: null, message: '' };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const profileData = deserializeData(_.cloneDeep(this.props.profile));

    this.setState({ ...profileData, message: '' });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value, message: '' });
  }

  onSubmit(e) {
    e.preventDefault();

    const { message, ...profile } = this.state;
    const { translate, startSetImpersonateData } = this.props;

    if (_.isEmpty(profile.group)) {
      this.setState({ message: translate('impersonate_group') });
      return;
    }
    const { groupId, accountId } = serializeProfile(profile);
    startSetImpersonateData(groupId, accountId);

    history.push('/group-overview');
  }

  render() {
    const { getFilteredGroups, translate } = this.props;

    return (
      <Form onSubmit={this.onSubmit}>
        <ModalHeader toggle={this.props.toggleModal}>
          {translate('impersonate')}
        </ModalHeader>
        <ModalBody>
          <Card>
            {this.state.message && (
              <CardHeader>
                <p>{this.state.message}</p>
              </CardHeader>
            )}
            <CardBody>
              <FormGroup>
                <Label>{translate('groups')}</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <AsyncSelect
                    name="group"
                    multiple={false}
                    value={this.state.group}
                    placeholder={translate('groups_select')}
                    onSelect={this.handleInputChange}
                    getData={getFilteredGroups}
                  />
                </InputGroup>
              </FormGroup>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="orange">
            <i className="far fa-check" /> {translate('impersonate')}
          </Button>
          <Button color="secondary" onClick={this.props.toggleModal}>
            <i className="fal fa-times-circle" /> {translate('general_cancel')}
          </Button>
        </ModalFooter>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.profile.profileData
});

const mapDispatchToProps = dispatch => ({
  startSetImpersonateData: (groupId, accountId, groupData) =>
    dispatch(startSetImpersonateData(groupId, accountId, groupData)),
  getFilteredGroups: (page, perPage, query) =>
    dispatch(getFilteredGroups(page, perPage, query))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateModal);
