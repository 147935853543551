import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func,
  modalClass: PropTypes.string,
  className: PropTypes.string,
  modalType: PropTypes.string,
  children: PropTypes.node
};

const defaultProps = {
  isOpen: false,
  modalClass: 'primary',
  className: '',
  modalType: ''
};

class ModalWrapper extends React.Component {
  render() {
    const { isOpen, toggleModal, modalClass, children } = this.props;
    let { className } = this.props;
    let { modalType } = this.props;
    const isPrimary = modalClass.indexOf('primary') !== -1;
    // modal type import_summary then we have to extend min-width because we are showing all the table there that is why we need more space
    className += isPrimary
      ? modalType === 'IMPORT_SUMMARY'
        ? 'modal-wrapper mdl-wpr-ext-width'
        : 'modal-wrapper'
      : '';
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className={`modal-${modalClass} ${className}`}
        centered={!isPrimary}
        fade={true}
      >
        {children}
      </Modal>
    );
  }
}

ModalWrapper.propTypes = propTypes;
ModalWrapper.defaultProps = defaultProps;
export default ModalWrapper;
