// Generic Reducer

const genericReducerDefaultState = {
  items: [],
  activeTab: undefined,
  itemID: null,
  query: '',
  currentPage: 1,
  nextPage: null,
  totalItems: 0,
  sortField: '',
  sortOrder: '',
  additionalData: {},
  modalShow: false,
  modalType: '',
  modalMessage: '',
  modalPrompt: '',
  isLoading: true,
  requestFailed: false,
  listView: ''
};

export default (state = genericReducerDefaultState, action) => {
  switch (action.type) {
    case 'GENERIC_SET_ITEMS':
      return {
        ...state,
        items: action.items,
        query: action.query,
        currentPage: action.currentPage,
        totalItems: action.totalItems,
        sortField: action.sortField,
        sortOrder: action.sortOrder,
        requestFailed: false,
        isLoading: false
      };
    case 'GENERIC_SET_LOADING':
      return {
        ...state,
        isLoading: true,
        listView: action.listView
      };
    case 'GENERIC_SET_ITEM_DATA':
      return {
        ...state,
        itemID: action.itemID,
        nextPage: action.nextPage
      };
    case 'GENERIC_SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.activeTab,
        query: ''
      };
    case 'GENERIC_SET_ADDITIONAL_DATA':
      return {
        ...state,
        additionalData: action.additionalData
      };
    case 'GENERIC_UPDATE_ADDITIONAL_DATA':
      return {
        ...state,
        additionalData: action.additionalData
      };
    case 'GENERIC_TOGGLE_MODAL':
      // Prevent modal toggle if there are pending changes
      if (state.modalPrompt) {
        return {
          ...state,
          modalPrompt: 'TOGGLED'
        };
      }

      return {
        ...state,
        modalShow: !state.modalShow,
        modalType: action.modalType,
        modalPrompt: ''
      };
    case 'GENERIC_MODAL_MESSAGE':
      return {
        ...state,
        modalType: 'MODAL_MESSAGE',
        modalMessage: action.modalMessage
      };
    case 'GENERIC_MODAL_PROMPT':
      return {
        ...state,
        modalPrompt: action.modalPrompt
      };
    case 'GENERIC_UPDATE_ITEM':
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              ...action.updates
            };
          } else {
            return item;
          }
        })
      };
    case 'GENERIC_API_ERROR':
      return {
        ...genericReducerDefaultState,
        isLoading: false,
        requestFailed: true,
        listView: state.listView
      };
    case 'GENERIC_RESET_ITEMS':
      return {
        ...state,
        ...genericReducerDefaultState
      };
    default:
      return state;
  }
};
