import React, { useRef } from 'react';
import {
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col
} from 'reactstrap';
import { apiErrorHandler } from '../../actions/generic_items';

import ImageUpload from '../../components/ImageUpload/ImageUpload';

const AvatarModal = props => {
  const { toggleModal, translate } = props;
  const uploadRef = useRef();

  const onSubmit = event => {
    event.preventDefault();
    const { startReloadData } = props;
    const onSaveAvatar = uploadRef?.current?.onSaveAvatar;

    onSaveAvatar()
      .then(() => {
        startReloadData();
        toggleModal();
      })
      .catch(error => {
        apiErrorHandler(error);
      });
  };

  return (
    <Form onSubmit={onSubmit}>
      <ModalHeader toggle={toggleModal}>{translate('avatar_edit')}</ModalHeader>
      <ModalBody>
        <Col xs="6">
          <ImageUpload
            src={props.avatar}
            ref={uploadRef}
            startSetAvatar={props.startSetAvatar}
          />
        </Col>
      </ModalBody>
      <ModalFooter>
        <React.Fragment>
          <Button type="submit" color="orange">
            <i className="far fa-check" /> {translate('general_save')}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            <i className="fal fa-times-circle" /> {translate('general_cancel')}
          </Button>
        </React.Fragment>
      </ModalFooter>
    </Form>
  );
};

export default AvatarModal;
