import React from 'react';
import PropTypes from 'prop-types';
import PasswordStrengthBar from 'react-password-strength-bar';

/* https://github.com/lannex/react-password-strength-bar */

const propTypes = {
  password: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  scoreWords: PropTypes.array,
  shortScoreWords: PropTypes.string,
  barColors: PropTypes.array
};

const defaultProps = {
  minLength: 8,
  scoreWords: [
    'password_strength_weak', // common and extremely bad passwords
    'password_strength_weak',
    'password_strength_okay',
    'password_strength_good',
    'password_strength_strong'
  ],
  shortScoreWord: 'password_strength_too_short',
  barColors: ['#EEE', '#f2453d', '#f17725', '#0091ec', '#8dc252'] //colors from _variables.css
};

const PasswordStrengthMeter = props => {
  const {
    password,
    translate,
    minLength,
    scoreWords,
    shortScoreWord,
    barColors
  } = props;

  const translated = scoreWords.map(key => translate(key));

  return (
    <PasswordStrengthBar
      password={password}
      minLength={minLength}
      shortScoreWord={translate(shortScoreWord)}
      scoreWords={translated}
      barColors={barColors}
    />
  );
};

PasswordStrengthMeter.propTypes = propTypes;
PasswordStrengthMeter.defaultProps = defaultProps;

export default PasswordStrengthMeter;
