import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CardFooter,
  Button
} from 'reactstrap';

import { validateResetToken, startResetPassword } from '../../../actions/auth';

import { AppContext } from '../../../AppContext';
import { simpleErrorHandler } from '../../../helpers/api_error';

import logo from '../../../assets/img/brand/logo.svg';
import PasswordStrengthMeter from '../../../components/passwordStrengthMeter/PasswordStrengthMeter';

class PasswordResetForm extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      new_password: '',
      show_password: false,
      confirm_password: '',
      show_confirm_password: false,
      token: '',
      isReset: false,
      message: ''
    };
  }

  componentDidMount() {
    const query = this.props.location.search;
    const token = query.replace(/\?token=/, '');

    this.props.validateResetToken(token).then(() => {
      this.setState({ token });
    });
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const isValid = target.validity.valid;

    if (value && !isValid) return;

    this.setState({ [name]: value });
  };

  handleLanguageChange = lang => {
    this.context.setActiveLanguage(lang);
  };

  onSubmit = event => {
    event.preventDefault();

    const { translate } = this.context;
    const {
      show_password,
      show_confirm_password,
      isReset,
      message,
      ...passwordReset
    } = this.state;

    if (passwordReset.new_password !== passwordReset.confirm_password) {
      this.setState({
        message: translate('user_management_password_error_match')
      });
      return;
    }

    this.props
      .startResetPassword(passwordReset)
      .then(() => {
        this.setState({ isReset: true, message: '' });
      })
      .catch(error => {
        const message = simpleErrorHandler(error, translate);

        this.setState({ message });
      });
  };

  render() {
    const { translate } = this.context;
    const {
      token,
      new_password,
      confirm_password,
      show_password,
      show_confirm_password,
      isReset,
      message
    } = this.state;

    const isDisabled = new_password.length < 8 || confirm_password.length < 8;

    return (
      <div className="login-page flex-row align-items-center login-img">
        <Card className="login-page-content">
          <CardBody>
            <div className="login-page-logo">
              <img src={logo} alt="Logo" height="80px" />
            </div>
            {isReset ? (
              <div>
                <i className="fal fa-check-circle fa-2x text-success" />
                <span> {translate('password_reset_success')}</span>
                <a
                  href="/"
                  target="_self"
                  className="mt-2 btn btn-orange btn-block"
                >
                  {translate('login_sign_in')}
                </a>
              </div>
            ) : (
              <Form onSubmit={this.onSubmit}>
                {message && (
                  <span className="text-danger" style={{ whiteSpace: 'pre' }}>
                    {message}
                  </span>
                )}
                {!token && (
                  <div>
                    <p className="text-danger mb-1">
                      {translate('password_reset_help_text')}
                    </p>
                    <Link to="reset_password/forgot">
                      {translate('password_reset_redo')}
                    </Link>
                  </div>
                )}
                <InputGroup className="my-2">
                  <span className="modal-setting-title">
                    {translate('user_management_new_password')}
                  </span>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={show_password ? 'text' : 'password'}
                    name="new_password"
                    value={new_password}
                    onChange={this.handleInputChange}
                    placeholder={translate('user_management_new_password')}
                    pattern="(\S)*"
                    autoComplete="off"
                    autoFocus
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <span
                        className="textbox-button"
                        onClick={() =>
                          this.setState({
                            show_password: !show_password
                          })
                        }
                      >
                        <i
                          className={`fa fa-${
                            show_password ? 'eye' : 'eye-slash'
                          }`}
                        />
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <PasswordStrengthMeter
                  password={new_password}
                  translate={translate}
                />
                <InputGroup className="mb-3">
                  <span className="modal-setting-title">
                    {translate('user_management_confirm_password')}
                  </span>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="far fa-lock" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={show_confirm_password ? 'text' : 'password'}
                    name="confirm_password"
                    value={confirm_password}
                    onChange={this.handleInputChange}
                    placeholder={translate('user_management_confirm_password')}
                    pattern="(\S)*"
                    autoComplete="off"
                    required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <span
                        className="textbox-button"
                        onClick={() =>
                          this.setState({
                            show_confirm_password: !show_confirm_password
                          })
                        }
                      >
                        <i
                          className={`fa fa-${
                            show_confirm_password ? 'eye' : 'eye-slash'
                          }`}
                        />
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <Button
                  type="submit"
                  color="orange"
                  className="btn btn-orange btn-block"
                  disabled={isDisabled}
                >
                  {translate('password_reset')}
                </Button>
              </Form>
            )}
          </CardBody>
          <CardFooter>
            <div className="text-center">
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('en')}
              >
                English
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('nl')}
              >
                Nederlands
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('fr')}
              >
                Français
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  validateResetToken: token => dispatch(validateResetToken(token)),
  startResetPassword: data => dispatch(startResetPassword(data))
});

export default connect(null, mapDispatchToProps)(PasswordResetForm);
