// CallFlow Reducer

const callflowGUIReducerDefaultState = {
  types: [
    { id: 'contact', label: 'Contact' },
    { id: 'extension', label: 'Extension' },
    { id: 'ring_group', label: 'Ring Group' },
    { id: 'number', label: 'Number' }
  ],
  ringtone: [],
  tts_languages: [],
  tts_voices: [],
  timezones: [],
  stt_languages: [],
  usedObjects: {},
  apiDbInfo: {},
  updateTracker: 0,
  allowCanvasTranslation: true,
  allowCanvasZoom: true,
  isLoading: true,
  noErrors: true,
  message: ''
};

export default (state = callflowGUIReducerDefaultState, action) => {
  switch (action.type) {
    case 'CALLFLOW_GUI_SET_CALLFLOW_DATA':
      return {
        ...state,
        ringtone: action.ringtone,
        tts_languages: action.tts_languages,
        tts_voices: action.tts_voices,
        timezones: action.timezones,
        stt_languages: action.stt_languages,
        usedObjects: action.usedObjects,
        apiDbInfo: action.apiDbInfo,
        isLoading: false
      };
    case 'CALLFLOW_GUI_SET_ERROR':
      return {
        ...state,
        isLoading: false,
        noErrors: false,
        message: action.message
      };
    case 'CALLFLOW_GUI_LOCK_DIAGRAM':
      return {
        ...state,
        allowCanvasTranslation: !action.lock,
        allowCanvasZoom: !action.lock
      };
    case 'CALLFLOW_GUI_UPDATE_DIAGRAM':
      return {
        ...state,
        updateTracker: state.updateTracker + 1
      };
    case 'CALLFLOW_GUI_RESET_TRACKER':
      return {
        ...state,
        updateTracker: 0
      };
    case 'CALLFLOW_GUI_RESET_DATA':
      return {
        ...state,
        isLoading: true,
        noErrors: true,
        message: ''
      };
    default:
      return state;
  }
};
