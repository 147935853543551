// Statistics Reducer

const statisticsReducerDefaultState = {
  amountOfIncomingCallsWidgetData: {},
  amountOfOutgoingCallsWidgetData: {},
  unansweredCallsWorkingHoursWidgetData: {},
  unansweredCallsOffHoursWidgetData: {},
  longestCallDurationWidgetData: {},
  waitingTimeOver20WidgetData: {},
  currentTotalIncomingCallsBarData: [],
  previousTotalIncomingCallsBarData: [],
  totalIncomingCallsBarChartsColors: ['#FFF6A3', '#F5A638'],
  currentAverageWaitingTimeBarData: [],
  previousAverageWaitingTimeBarData: [],
  averageWaitingTimeBarChartsColors: ['#B5E7FF', '#3445F4'],
  pieChartData: [],
  pieChartColorScale: ['#2E3EDE', '#FC9A42'],
  barChartData: [],
  barChartColor: '#821AED',
  horizontalBarChartData: [],
  horizontalBarChartColor: '#005FAF',
  isLoading: true,
  noErrors: true,
  message: ''
};

export default (state = statisticsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_STATISTICS_DATA':
      return {
        ...state,
        amountOfIncomingCallsWidgetData: action.amountOfIncomingCallsWidgetData,
        amountOfOutgoingCallsWidgetData: action.amountOfOutgoingCallsWidgetData,
        unansweredCallsWorkingHoursWidgetData:
          action.unansweredCallsWorkingHoursWidgetData,
        unansweredCallsOffHoursWidgetData:
          action.unansweredCallsOffHoursWidgetData,
        longestCallDurationWidgetData: action.longestCallDurationWidgetData,
        waitingTimeOver20WidgetData: action.waitingTimeOver20WidgetData,
        currentTotalIncomingCallsBarData:
          action.currentTotalIncomingCallsBarData,
        currentAverageWaitingTimeBarData:
          action.currentAverageWaitingTimeBarData,
        barChartData: action.barChartData,
        pieChartData: action.pieChartData,
        horizontalBarChartData: action.horizontalBarChartData,
        isLoading: false
      };
    case 'RESET_STATISTICS':
      return {
        ...statisticsReducerDefaultState
      };
    default:
      return state;
  }
};
