// Live Statistics Reducer

const liveStatisticsReducerDefaultState = {
  liveAgentsNumericData: 0,
  peopleInQueueNumericData: 0,
  peopleInCallsNumericData: 0,
  longestCurrentWaitingTimeNumericData: '',
  peopleInQueueTableData: [],
  liveAgentsTableData: [],
  isLoading: true,
  noErrors: true,
  message: ''
};

export default (state = liveStatisticsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_LIVE_STATISTICS_DATA':
      return {
        ...state,
        liveAgentsNumericData: action.liveAgentsNumericData,
        peopleInQueueNumericData: action.peopleInQueueNumericData,
        peopleInCallsNumericData: action.peopleInCallsNumericData,
        longestCurrentWaitingTimeNumericData:
          action.longestCurrentWaitingTimeNumericData,
        peopleInQueueTableData: action.peopleInQueueTableData,
        liveAgentsTableData: action.liveAgentsTableData,
        isLoading: false
      };
    case 'RESET_LIVE_STATISTICS': {
      return {
        ...liveStatisticsReducerDefaultState
      };
    }
    default:
      return state;
  }
};
