export default [
  {
    name: 'general',
    transKey: 'dashboard_modal_category_general',
    children: [
      { name: 'phonebookWidget', transKey: 'dashboard_modal_phonebook' },
      { name: 'timeScheduleWidget', transKey: 'dashboard_modal_time_schedule' }
    ]
  },
  {
    name: 'sidebar',
    transKey: 'dashboard_modal_category_sidebar',
    children: [
      {
        name: 'callForwardingWidget',
        transKey: 'dashboard_modal_call_forwarding'
      },
      { name: 'voicemailWidget', transKey: 'dashboard_modal_voicemail' },
      {
        name: 'manageDevicesWidget',
        transKey: 'dashboard_modal_manage_devices'
      },
      { name: 'helpWidget', transKey: 'dashboard_modal_help' },
      { name: 'webinarsWidget', transKey: 'dashboard_modal_webinars' },
      { name: 'callFlowTogglesWidget', transKey: 'dashboard_callflow_toggles' }
      //{ name: 'trendsWidget', transKey: 'dashboard_modal_trends' }
    ]
  },
  /*{
    name: 'stats',
    transKey: 'dashboard_modal_category_stats',
    children: [
      { name: 'statsChartWidget', transKey: 'dashboard_modal_stats_chart' },
      { name: 'statsWidget', transKey: 'dashboard_modal_stats' }
    ]
  },*/
  {
    name: 'calls',
    transKey: 'dashboard_modal_category_calls',
    children: [
      { name: 'latestCallsWidget', transKey: 'dashboard_modal_latest_calls' }
    ]
  }
];
