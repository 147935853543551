import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// views
import { DefaultLayout } from '../containers';
import Login from '../views/Pages/Login/';
import PasswordReset from '../views/Pages/PasswordReset/PasswordReset';
import PasswordResetForm from '../views/Pages/PasswordReset/PasswordResetForm';
import { Page404 } from '../views/Pages/Page404';
import Integrations from '../views/Integrations/Integrations';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute
          path="/reset_password"
          component={PasswordResetForm}
          exact={true}
        />
        <PublicRoute path="/" component={Login} exact={true} />
        <PublicRoute
          path="/reset_password/forgot"
          component={PasswordReset}
          exact={true}
        />
        <PrivateRoute
          path="/integrations/:kind/activate/"
          component={Integrations}
        />
        <PrivateRoute path="/" component={DefaultLayout} />
        <Route component={Page404} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;
