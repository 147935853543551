import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  Button,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';

import { requestResetPassword } from '../../../actions/auth';
import { AppContext } from '../../../AppContext';

import logo from '../../../assets/img/brand/logo.svg';
import { simpleErrorHandler } from '../../../helpers/api_error';

class PasswordReset extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isValid: false,
      isSent: false,
      message: ''
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const isValid = target.validity.valid;

    this.setState({ [name]: value, isValid });
  };

  handleLanguageChange = lang => {
    this.context.setActiveLanguage(lang);
  };

  onSubmit = e => {
    e.preventDefault();

    const { translate } = this.context;
    const { requestResetPassword } = this.props;
    const { email } = this.state;

    requestResetPassword(email)
      .then(() => {
        this.setState({ isSent: true, message: '' });
      })
      .catch(error => {
        const message = simpleErrorHandler(error, translate);

        this.setState({ message });
      });
  };

  render() {
    const { translate } = this.context;
    const { email, isValid, isSent, message } = this.state;

    return (
      <div className="login-page flex-row align-items-center login-img">
        <Card className="login-page-content">
          <CardBody>
            <div className="login-page-logo">
              <img src={logo} alt="Logo" height="80px" />
            </div>
            <Form onSubmit={this.onSubmit}>
              {message && <span className="text-danger">{message}</span>}
              {isSent ? (
                <div>
                  <i className="fal fa-check-circle fa-2x text-success" />
                  <span> {translate('password_reset_email_sent')}</span>
                  <p className="text-muted">
                    {translate('password_reset_email_sent_help_text')}
                  </p>
                </div>
              ) : (
                <p className="text-muted mt-3">
                  {translate('password_reset_email_help_text')}
                </p>
              )}
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="far fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="email"
                  name="email"
                  autoFocus
                  autoComplete="off"
                  value={email}
                  onChange={this.handleInputChange}
                  placeholder={translate('password_reset_email')}
                  required
                />
              </InputGroup>
              <Button
                type="submit"
                color="orange"
                className="btn btn-orange btn-block mt-4"
                disabled={!isValid}
              >
                {translate('password_reset_email_send')}
              </Button>
              <span className="btn-block text-center mt-2">
                <a
                  href="https://www.telsmart.eu/support"
                  color="primary"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  {translate('login_help')}
                </a>
              </span>
            </Form>
          </CardBody>
          <CardFooter>
            <div className="text-center">
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('en')}
              >
                English
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('nl')}
              >
                Nederlands
              </Button>
              <Button
                type="button"
                className="btn btn-lang"
                onClick={() => this.handleLanguageChange('fr')}
              >
                Français
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  requestResetPassword: email => dispatch(requestResetPassword(email))
});

export default connect(null, mapDispatchToProps)(PasswordReset);
