import axios from 'axios';
import apiRequest from '../helpers/api';

export const activateIntegration = (kind, key, activation_key) => {
  return () => {
    const url = `integrations/subscribe/${kind}/`;

    const data = {
      [activation_key]: key
    };

    const config = apiRequest('POST', url, data);

    return axios(config);
  };
};

export const deactivateIntegration = kind => {
  return () => {
    const url = `integrations/unsubscribe/${kind}/`;

    const config = apiRequest('POST', url);

    return axios(config);
  };
};

export const getIntegration = id => {
  return () => {
    const url = `integrations/${id}/`;

    const config = apiRequest('GET', url);

    return axios(config);
  };
};

export const getAllIntegrations = id => {
  return () => {
    const url = `integrations/`;

    const config = apiRequest('GET', url);

    return axios(config);
  };
};
