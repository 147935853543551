// returns string containing error message
export const simpleErrorHandler = (error, translate, type = 'Item') => {
  let message = translate('apiError_request');
  const modelName = name => {
    switch (name) {
      case 'EXTENSIONS':
        return translate('apiError_extensions');
      case 'FIFO_QUEUES':
        return translate('apiError_fifoqueues');
      case 'RING_GROUPS':
        return translate('apiError_groups');
      case 'VOICEMAIL_BOXES':
        return translate('apiError_voicemail');
      case 'PHONEBOOK':
        return translate('apiError_phonebook');
      case 'CALL_FLOWS':
        return translate('apiError_callflow');
      case 'TIME_SCHEDULE':
        return translate('apiError_timeschedule');
      default:
        return translate('apiError_notdefined');
    }
  };

  try {
    const res = error.response.data;
    //console.log('res', res);

    if (typeof res === 'string') {
      // Check if request url is invalid
      if (res.indexOf('Page not found') !== -1) {
        message = translate('apiError_request');
        return message;
      }

      // Check if voicemailbox isn't empty
      if (res.indexOf('empty') !== -1) {
        message = translate('apiError_voicemail_not_empty');
      }

      // Check if item is in use by call flow
      if (res.indexOf('call flows') !== -1) {
        message = translate('apiError_callflow_used');
      }

      // Check if item is linked to another model
      if (res.indexOf('IntegrityError') !== -1) {
        // Check if item is linked to device model
        if (res.indexOf('provisioning_device') !== -1) {
          message = translate('apiError_device');
        }
      }

      // Check if item is used by device
      if (res.indexOf('device') !== -1) {
        message = translate('apiError_device');
      }

      // Check if item is linked to did
      if (res.indexOf('DID') !== -1) {
        message = translate('apiError_did_used');
      }

      // Check if item is in use by extension
      if (res.indexOf('in use in extension') !== -1) {
        message = translate('apiError_extensions_used');
      }

      // Check if phonebook contact is in use by queue
      if (res.indexOf('Contact is in use in existing fifo queue(s)') !== -1) {
        message = translate('apiError_phonebook_contact_queue');
      }

      if (
        res.indexOf(
          'At least one of the contacts is in use in existing fifo queue(s)'
        ) !== -1
      ) {
        message = translate('apiError_phonebook_contacts_queue');
      }

      // Check if call flow block in mongo has no mysql reference
      if (res.indexOf('CallFlow exists in Mongo, but not in MySQL') !== -1) {
        message = translate('apiError_callflow_reference');
      }

      if (res.indexOf(`Password doesn't meet all the requirements.`) !== -1) {
        message = translate('apiError_password_req');
      }
    }

    // Check if call flow extension is in use
    if (
      res.hasOwnProperty('non_field_errors') &&
      res.non_field_errors[0].indexOf(
        'Extension is already in use in CallFlow'
      ) !== -1
    ) {
      message = `${translate('apiError_callflow_extension')}: ${res.call_flow}`;
    }

    // Check if extension has valid email
    if (
      res.hasOwnProperty('email_address') &&
      res.email_address[0].indexOf('Enter a valid email address') !== -1
    ) {
      message = translate('apiError_extensions_email');
    }

    // Check if ring group name is unique
    if (
      res.hasOwnProperty('non_field_errors') &&
      res.non_field_errors[0].indexOf('unique') !== -1
    ) {
      message = `${type} ${translate('apiError_groups_unique')}`;
    }

    // Check if phonebook is in use by device
    if (
      res.hasOwnProperty('status') &&
      res.status.indexOf('Phonebook is used in a device') !== -1
    ) {
      const devices = res.object.join(', ');

      message = `${translate('apiError_phonebook_devices')} ${devices}`;
    }

    // Check if media is in use by queue
    if (
      res.hasOwnProperty('status') &&
      res.status.indexOf('Media file is used in a queue') !== -1
    ) {
      const queues = res.object.join(', ');

      message = `${translate('apiError_media_file_queue')} ${queues}`;
    }

    // Check if media is in use by call flow
    if (
      res.hasOwnProperty('status') &&
      res.status.indexOf('Media file is used in existing call flows') !== -1
    ) {
      const callflows = res.object.join(', ');

      message = `${translate('apiError_media_file_callflow')} ${callflows}`;
    }

    // Check if voicemail is in use by call flow
    if (
      res.hasOwnProperty('status') &&
      res.status.indexOf('Voicemail is in use in existing call flows') !== -1
    ) {
      const callflows = res.object.join(', ');

      message = `${translate('apiError_voicemail_callflow')} ${callflows}`;
    }

    // Check if voicemail is in use by device
    if (
      res.hasOwnProperty('status') &&
      res.status.indexOf('Voicemail box is used in a provisioning device') !==
        -1
    ) {
      const devices = res.object.join(', ');

      message = `${translate('apiError_voicemail_device')} ${devices}`;
    }

    // Check if site is in use by extension
    if (
      res.hasOwnProperty('status') &&
      res.status.indexOf('Site is in use in extension') !== -1
    ) {
      const extensions = res.object.join(', ');

      message = `${translate('apiError_sites_extension')} ${extensions}`;
    }

    // Check if media file reference is unique
    if (
      res.hasOwnProperty('non_field_errors') &&
      res.non_field_errors[0].indexOf('Reference') !== -1
    ) {
      message = `${translate('apiError_media_file_reference_unique')}`;
    }

    // Check if phonebook contact number is unique
    if (
      res.hasOwnProperty('non_field_errors') &&
      res.non_field_errors[0].indexOf('phonebook') !== -1
    ) {
      message = `${translate('did_routing_error_did_unique_check')}`;
    }

    // Check if phonebook contact mobile number is unique
    if (
      res.hasOwnProperty('non_field_errors') &&
      res.non_field_errors[0].indexOf('phonebook, mobile') !== -1
    ) {
      message = `${translate('apiError_phonebook_contact_mobile')}`;
    }

    // Check if ring groups/fifo queues extensions are unique
    if (res.hasOwnProperty('extension')) {
      // Check if extension is higher than 32767
      if (res.extension[0].indexOf('32767') !== -1) {
        message = translate('apiError_extensions_value');
      }

      const dataArray = res.extension[0].split(';');

      if (dataArray[0] === 'EXT_EXISTS') {
        message = `${translate('apiError_extensions_unique_prefix')} ${
          dataArray[2]
        } ${translate('apiError_extensions_unique')} ${modelName(
          dataArray[1]
        )} ${translate('apiError_extensions_unique_sufix')}`;
      }

      // Check multiple extension errors
      if (dataArray[0] === 'EXT_EXISTS_MULTIPLE') {
        const modelNamesArray = dataArray[1].split(',');
        const names = modelNamesArray.map(model => modelName(model)).join(', ');

        message = `${translate('apiError_extensions_unique_prefix')} ${
          dataArray[2]
        } ${translate('apiError_extensions_unique_multiple')} ${names}`;
      }
    }

    // Check if did is protected
    if (
      res.hasOwnProperty('non_field_errors') &&
      res.non_field_errors[0].indexOf('DID is protected') !== -1
    ) {
      message = `${translate('did_routing_error_protected')}`;
    }

    // Check if dids are unique
    if (res.hasOwnProperty('did')) {
      const dataText = res.did[0];
      const errorText = 'DID Routing with this did already exists.';

      if (dataText === errorText) {
        message = translate('did_routing_error_did_unique_check');
      }
    }

    if (Array.isArray(res)) {
      // Password reset message(s)
      if (res[0].indexOf('password') !== -1) {
        message = '';

        res.forEach(error => {
          switch (error) {
            case 'This password is too common.':
              message += `${translate('apiError_password_common')}\r\n`;
              break;
            case 'This password is entirely numeric.':
              message += `${translate('apiError_password_numeric')}\r\n`;
              break;
            case 'The password must contain at least 1 lowercase letter, a-z.':
              message += `${translate('apiError_password_lowercase')}\r\n`;
              break;
            case 'The password must contain at least 1 uppercase letter, A-Z.':
              message += `${translate('apiError_password_uppercase')}\r\n`;
              break;
            case 'The password must contain at least 1 digit, 0-9.':
              message += `${translate('apiError_password_number')}\r\n`;
              break;
            default:
              message += `${translate('user_management_password_error')}\r\n`;
              break;
          }
        });
      } else {
        const dataArray = res[0].split(';');

        // Check call flow toggle block extensions
        if (dataArray[0] === 'EXT_EXISTS_TOGGLE') {
          const extensions = dataArray[2].split(',').join(', ');

          message = `${translate(
            'apiError_toggle_prefix'
          )} ${extensions} ${translate('apiError_toggle_sufix')} ${
            dataArray[1]
          }`;
        }
      }
    }

    // Check if phonebook extension exists
    if (res.hasOwnProperty('shortcode')) {
      const dataArray = res['shortcode'][0].split(';');

      if (dataArray[0] === 'EXT_EXISTS') {
        message = translate('apiError_phonebook_extension');
      }
    }

    // Check if call flow is in use in dids
    if (res.hasOwnProperty('error') && res.error.indexOf('DIDs') !== -1) {
      message = translate('apiError_callflow_did');
    }

    // Check if call flow gui already exists
    if (
      res.hasOwnProperty('detail') &&
      res.detail.indexOf('Duplicate GUI') !== -1
    ) {
      message = translate('apiError_contact_help');
    }

    // Check if pricing changed
    if (
      res.hasOwnProperty('price') &&
      res.price[0].indexOf('Price value has been changed') !== -1
    ) {
      message = `${translate('media_library_request_error_price')}`;
    }
  } catch (err) {
    message = translate('apiError_request');
  }

  return message;
};

// returns object containing error message object
export const complexErrorHandler = (error, translate) => {
  const message = {
    type: 'API_ERROR',
    message: translate('apiError_request')
  };

  try {
    const res = error.response.data;
    //console.log('res', res);

    // Check phonebook import
    if (res.hasOwnProperty('invalid_numbers')) {
      message.type = 'INVALID_NUMBERS';
      message.message = `${translate('apiError_phonebook_import')}`;
      message.numbers = res.invalid_numbers;
    }

    // Check if queue is in use
    if (res.status && res.status.indexOf('Queue is in use') !== -1) {
      const callflows = res.object.join(', ');

      message.type = 'QUEUE_USED';
      message.message = translate('apiError_fifoqueues_used');
      message.callflows = callflows;
    }
  } catch (err) {
    message.message = translate('apiError_request');
  }

  return message;
};
