import React, {
  useState,
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef
} from 'react';
import _ from 'lodash';
import { FormGroup, UncontrolledTooltip } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import Dropzone from 'react-dropzone';
import 'bootstrap4c-dropzone/dist/css/component-dropzone.min.css';

import { AppContext } from '../../AppContext';

const cropDefaultState = {
  x: 0,
  y: 0,
  width: 100,
  height: 100,
  unit: '%',
  aspect: 1 / 1
};

const ImageUpload = forwardRef((props, ref) => {
  const { translate } = useContext(AppContext);
  const dropzoneRef = useRef();

  const [file, setFile] = useState([]);
  const [image, setImage] = useState('');
  const [src, setSrc] = useState('');
  const [crop, setCrop] = useState(cropDefaultState);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (props.src) setSrc(props.src);

    // Clean up on component unmount
    return () => {
      if (src) URL.revokeObjectURL(src);
    };
    // eslint-disable-next-line
  }, []);

  const onDrop = file => {
    /* 
      Dropzone creates a File object
      ReactCrop accepts path, blob url or base64 data
      Since File provides none of the above, we use URL.createObjectURL to make a blob url
      Each time the img is overriden, we have to make sure to unload the previous one
    */
    if (src) URL.revokeObjectURL(src);

    const fileUrl = URL.createObjectURL(file[0]);

    setFile(file[0]);
    setSrc(fileUrl);
    setMessage('');
  };

  const onImageLoaded = image => {
    const { width, height, naturalWidth, naturalHeight } = image;

    // On new image upload, set crop while keeping aspect ratio
    if (width !== naturalWidth && height !== naturalHeight) {
      const cropValue = width > height ? height * 0.5 : width * 0.5;

      setCrop({
        ...crop,
        x: 0,
        y: 0,
        width: cropValue,
        height: cropValue,
        unit: 'px'
      });
    }
    setImage(image);

    return false;
  };

  // Create new image out of cropped area and convert to blob
  const getCroppedImg = () => {
    // Skip generating crop if whole img is selected
    if (crop.width === image.width && crop.height === image.height) {
      return file;
    }

    const canvas = document.createElement('canvas');
    /*
      Data in crop is using rendered element's values (x, y, width, height)
      Everything needs to be scaled back to image's original size before saving 
    */
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;

    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');

    // Cropped image smoothing
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    return new Promise((resolve, reject) => {
      const name = file?.name || new Date().getTime;
      const type = file?.type || 'jpeg';

      const callback = blob => {
        blob.name = name;
        resolve(blob);
      };

      canvas.toBlob(callback, type, 1);
    });
  };

  // Expose func to parent through ref
  useImperativeHandle(ref, () => ({
    onSaveAvatar: async () => {
      //Don't save if nothing changed
      if (props.src === src && _.isEqual(crop, cropDefaultState)) return;
      const avatar = await getCroppedImg();

      if (avatar.size > 10000000) {
        setMessage(translate('avatar_error_larger'));

        return;
      }

      const data = new FormData();
      data.append('avatar', avatar);

      return props.startSetAvatar(data);
    }
  }));

  return (
    <FormGroup>
      <span className="modal-setting-title">
        {translate('avatar')}
        {src && (
          <span className="dz-upload">
            <i
              id="uploadTootlip"
              className="fal fa-file-upload ml-2"
              onClick={event => {
                event.preventDefault();
                dropzoneRef.current.open();
              }}
            />
            <UncontrolledTooltip placement="right" target="uploadTootlip">
              {translate('phonebook_upload')}
            </UncontrolledTooltip>
          </span>
        )}
      </span>
      <div id="dropzone" className="dropzone img-dropzone cursor-pointer p-0">
        <Dropzone
          ref={dropzoneRef}
          accept={'image/jpeg, image/png'}
          multiple={false}
          onDrop={onDrop}
          // Disable upload prompt if img is set; drop will still work
          noClick={!!src}
        >
          {({ getRootProps, getInputProps, open }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {!src ? (
                <div className="dz-default dz-message px-4">
                  <span>
                    {translate('dropzone_message_1')}
                    <br />
                    {translate('dropzone_message_2')}
                  </span>
                </div>
              ) : (
                <React.Fragment>
                  <ReactCrop
                    src={src}
                    crossorigin="anonymous"
                    crop={crop}
                    maxHeight={2000}
                    maxWidth={2000}
                    keepSelection={true}
                    ruleOfThirds={true}
                    onChange={newCrop => setCrop(newCrop)}
                    onImageLoaded={onImageLoaded}
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </Dropzone>
      </div>
      <span className="text-danger">{message}</span>
    </FormGroup>
  );
});

export default ImageUpload;
