import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import PasswordStrengthMeter from '../../components/passwordStrengthMeter/PasswordStrengthMeter';

import {
  startChangePassword,
  profileUnsetInitialFlag
} from '../../actions/profile';

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      passwordSaved: false,
      message: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      message: ''
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { id, passwordSaved, message, ...changePassword } = this.state;
    const translate = this.props.translate;

    if (changePassword.newPassword !== changePassword.confirmPassword) {
      this.setState({
        message: translate('user_management_password_error_match')
      });
      return;
    }

    this.props
      .startChangePassword(changePassword)
      .then(() => {
        this.setState({
          message: translate('user_management_password_change_success'),
          passwordSaved: true
        });

        if (this.props.initialPassword) this.props.profileUnsetInitialFlag();
      })
      .catch(() => {
        this.setState({ message: translate('user_management_password_error') });
      });
  }

  render() {
    const initialPassword = this.props.initialPassword;
    const passwordSaved = this.state.passwordSaved;
    const translate = this.props.translate;

    return (
      <Form onSubmit={this.onSubmit}>
        <ModalHeader toggle={this.props.toggleModal}>
          {translate('user_management_change_password')}
        </ModalHeader>
        <ModalBody>
          <Card>
            {this.state.message && (
              <CardHeader>
                <p>{this.state.message}</p>
              </CardHeader>
            )}
            {!passwordSaved && (
              <CardBody>
                <FormGroup>
                  <span className="modal-setting-title">
                    {translate('user_management_old_password')}
                  </span>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="oldPassword"
                      placeholder={translate('user_management_old_password')}
                      value={this.state.oldPassword}
                      onChange={this.handleInputChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <span className="modal-setting-title">
                    {translate('user_management_new_password')}
                  </span>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="newPassword"
                      placeholder={translate('user_management_new_password')}
                      value={this.state.newPassword}
                      onChange={this.handleInputChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <PasswordStrengthMeter
                  password={this.state.newPassword}
                  translate={translate}
                />
                <FormGroup>
                  <span className="modal-setting-title">
                    {translate('user_management_confirm_password')}
                  </span>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="confirmPassword"
                      placeholder={translate(
                        'user_management_confirm_password'
                      )}
                      value={this.state.confirmPassword}
                      onChange={this.handleInputChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
              </CardBody>
            )}
          </Card>
        </ModalBody>
        <ModalFooter>
          {!passwordSaved ? (
            <React.Fragment>
              <Button type="submit" color="orange">
                <i className="far fa-check" /> {translate('general_save')}
              </Button>
              {!initialPassword && (
                <Button color="secondary" onClick={this.props.toggleModal}>
                  <i className="fal fa-times-circle" />{' '}
                  {translate('general_cancel')}
                </Button>
              )}
            </React.Fragment>
          ) : (
            <Button color="orange" onClick={this.props.toggleModal}>
              <i className="fal fa-times-circle" /> {translate('general_close')}
            </Button>
          )}
        </ModalFooter>
      </Form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startChangePassword: data => dispatch(startChangePassword(data)),
  profileUnsetInitialFlag: () => dispatch(profileUnsetInitialFlag())
});

export default connect(null, mapDispatchToProps)(ChangePasswordModal);
